<!--
 * @Author: Jason
 * @Description: 用户本人的信息
-->
<template>
    <div>
        <div class="card-container">
            <div class="card-heard">申请人信息</div>
            <el-form ref="userForm" :model="userForm" :rules="rules" label-width="180px" :label-position="labelPosition" class="demo-ruleForm">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="主申姓名：" prop="username">
                            <el-input v-model.trim="userForm.username" clearable maxlength="20" placeholder="请输入" @blur="usernameBlur" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="港澳通行证号码：" prop="passNumber">
                            <div style="display:flex;">
                                <el-input v-model.trim="userForm.passNumber" onkeyup="this.value=this.value.replace(/[, ]/g,'')" :disabled="disabled" style="width:185px;" clearable maxlength="20" placeholder="外国籍请填写护照号码" />
                                <el-checkbox v-model="checked" style="position:relative;top:-2px;left:12px;">暂未办理</el-checkbox>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="姓名拼音：">
                            <el-input v-model.trim="userForm.pinyin" clearable maxlength="50" placeholder="请输入" @input="pinyinInput"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="曾用名：" prop="usedName">
                            <el-input v-model.trim="userForm.usedName" clearable maxlength="20" placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="中国身份证号码：" prop="identityCard">
                            <el-input v-model.trim="userForm.identityCard" clearable maxlength="18" placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别：" prop="sex">
                            <el-select v-model="userForm.sex" style="width: 100%;" placeholder="请选择" clearable>
                                <el-option label="男" :value="1" />
                                <el-option label="女" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="香港身份证号码（如有）：" prop="hkidentityCard">
                            <el-input v-model.trim="userForm.hkidentityCard" clearable maxlength="50" placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号：" prop="mobile">
                            <el-input v-model.trim="userForm.mobile" clearable maxlength="11" placeholder="请输入" />
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="出生日期：" prop="birthday">
                            <el-date-picker v-model="userForm.birthday" value-format="yyyy-MM-dd" type="date" style="width:100%;" :picker-options="pickerOptions" placeholder="请选择" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱：" prop="email">
                            <el-input v-model.trim="userForm.email" clearable maxlength="50" placeholder="请输入" onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12" style="height: 50px;">
                        <el-form-item label="出生城市：" prop="birthPlaceAboard">
                            <el-radio-group v-model="userForm.birthPlaceAboard" @change="changeBirthPalce($event,userForm)">
                                <el-radio :label="0">国内</el-radio>
                                <el-radio :label="1">国外</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <span v-if="userForm.birthPlaceAboard === 0" style="position:relative;top:-52px;left:300px;">
                            <CitySelect v-model="userForm.birthPlace.area" style="width: 170px;" />
                        </span>
                        <span v-if="userForm.birthPlaceAboard === 1" style="position:relative;top:-52px;left:300px;">
                            <el-input v-model.trim="userForm.birthPlace.foreign" clearable style="width:170px" maxlength="100" placeholder="请输入" />
                        </span>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="现定居国家：" prop="liveCountry">
                            <el-input v-model.trim="userForm.liveCountry" clearable maxlength="20" placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="婚姻状况：" prop="married">
                            <el-select v-model="userForm.married" style="width: 100%" placeholder="请选择" clearable>
                                <el-option label="已婚" value="1" />
                                <el-option label="未婚" value="2" />
                                <el-option label="离婚" value="3" />
                                <el-option label="分居" value="4" />
                                <el-option label="丧偶" value="5" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="婚前姓氏（如有）：" prop="surname">
                            <el-input v-model.trim="userForm.surname" clearable maxlength="50" placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 50px;">
                        <el-form-item label="现居住地址：" prop="addressAboard">
                            <el-radio-group v-model="userForm.addressAboard" @change="changeAddress">
                                <el-radio :label="0">国内</el-radio>
                                <el-radio :label="1">国外</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <span v-if="userForm.addressAboard == 0" style="position:relative;top:-52px;left:288px;">
                            <CitySelect v-model="userForm.address.area" style="width: 150px;margin-left:20px" @input="getZipCode" />
                        </span>
                        <span v-if="userForm.addressAboard == 1" style="position:relative;top:-52px;left:288px;">
                            <el-input v-model.trim="userForm.address.foreign" style="width:150px;;margin-left:20px" maxlength="100" placeholder="请输入" />
                        </span>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="国籍：" prop="nationality">
                            <el-select v-model="userForm.nationality" style="width: 100%" clearable filterable remote reserve-keyword placeholder="请输入" :remote-method="queryCountryAsync" :loading="opctionLoading">
                                <el-option v-for="item in countryOption" :key="item.name_en" :label="item.name_cn + '-' + item.name_en" :value="item.name_cn + '-' + item.name_en" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="详细地址：" prop="address.details">
                            <el-input v-model.trim="userForm.address.details" type="textarea" placeholder="请输入详细地址" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮编：">
                            <el-input v-model="userForm.postcode" maxlength="20" placeholder="请输入邮编" @input="postcodeInput" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <div class="one-input doubles" style="position:relative;top:-50px;">
                    <el-form-item label="现定居国家：" label-width="140px" prop="liveCountry">
                        <div class="input-width">
                            <el-input v-model.trim="userForm.liveCountry" clearable maxlength="20" placeholder="请输入" />
                        </div>
                    </el-form-item>
                </div> -->
                <!-- <div style="position: relative;top: -48px;">
                    <div class="one-input" style="position:relative;top:-50px;">
                        <el-form-item label="婚姻状况：" prop="married">
                            <el-select v-model="userForm.married" style="width:300px;margin-left:0;" placeholder="请选择" clearable>
                                <el-option label="已婚" value="1" />
                                <el-option label="未婚" value="2" />
                                <el-option label="离婚" value="3" />
                                <el-option label="分居" value="4" />
                                <el-option label="丧偶" value="5" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="one-input" style="margin-left:60px;">
                        <el-form-item label="现居住地址：" label-width="140px" prop="addressAboard">
                            <el-radio-group v-model="userForm.addressAboard" @change="changeAddress">
                                <el-radio :label="0">国内</el-radio>
                                <el-radio :label="1">国外</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <span v-if="userForm.addressAboard == 0" style="position:relative;top:-52px;left:288px;">
                            <CitySelect v-model="userForm.address.area" style="width: 190px;" />
                        </span>
                        <span v-if="userForm.addressAboard == 1" style="position:relative;top:-52px;left:288px;">
                            <el-input v-model.trim="userForm.address.foreign" style="width:190px;" maxlength="100" placeholder="请输入" />
                        </span>
                    </div>
                </div> -->
                <!-- <div style="position:relative;top:-90px;">
                    <div class="one-input" style="position: relative;top:-15px;">
                        <el-form-item label="国籍：" label-width="140px" prop="nationality">
                            <el-select v-model="userForm.nationality" clearable filterable remote reserve-keyword placeholder="请输入" :remote-method="queryCountryAsync" :loading="opctionLoading">
                                <el-option v-for="item in countryOption" :key="item.name_en" :label="item.name_cn + '-' + item.name_en" :value="item.name_cn + '-' + item.name_en" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="one-input doubles">
                        <el-form-item label="详细地址：" label-width="140px" prop="address.details">
                            <div style="width:340px;">
                                <el-input v-model.trim="userForm.address.details" type="textarea" placeholder="请输入详细地址" />
                            </div>
                        </el-form-item>
                    </div>
                </div> -->
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="其他英语资格：" prop="englishSeniorityType" style="position: relative;">
                            <el-radio-group v-model="userForm.englishSeniorityType" @change="changeSeniorityType">
                                <el-radio :label="1">CET-6</el-radio>
                                <el-radio :label="2">其他</el-radio>
                            </el-radio-group>
                            <el-form-item v-if="userForm.englishSeniorityType === 1" :key="1" prop="seniorityName" style="position: absolute; top:0;right: -0px;margin:0">
                                <el-input v-model.trim="userForm.seniorityName" style="width:150px;" maxlength="100" placeholder="请输入CET-6分数" />
                            </el-form-item>
                            <el-form-item v-if="userForm.englishSeniorityType === 2" :key="2" prop="seniorityName" style="position: absolute; top:0;right: -0px;margin:0">
                                <el-input v-model.trim="userForm.seniorityName" style="width:150px;" maxlength="100" placeholder="请输入其他考试名称及分数" />
                            </el-form-item>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <div class="entrance-date">
                            <el-form-item label="预计入学日期：" label-width="140px" prop="enrollmentDate.date">
                                <el-date-picker v-model="userForm.enrollmentDate.date" type="year" placeholder="请选择年份" format="yyyy年" value-format="yyyy" :picker-options="entranceOptions" style="width: 130px;" />
                            </el-form-item>
                            <el-form-item prop="enrollmentDate.quarter" label-width="0">
                                <el-select v-model="userForm.enrollmentDate.quarter" placeholder="请选择季度" clearable style="width: 130px;">
                                    <el-option v-for="item in quarterList" :key="item.value" v-bind="item" />
                                </el-select>
                            </el-form-item>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div v-for="(item, index) in userForm.foreignLanguageMark" :key="index" class="en-score">
                            <!-- divide -->
                            <div v-if="index !== 0" class="divider" />
                            <!-- 类型 -->
                            <el-form-item
                                label="成绩类型：" :prop="`foreignLanguageMark[${index}].type`"
                                :rules="{ required: true, message: '请选择成绩类型', trigger: 'change' }"
                            >
                                <el-select v-model="item.type" placeholder="请选择成绩类型" clearable style="width:100%" @change="changeEnType($event, index)">
                                    <el-option v-for="enName in englishType" :key="enName.value" :label="enName.label" :value="enName.value" :disabled="enName.disabled" />
                                </el-select>
                            </el-form-item>
                            <!-- 分数 -->
                            <el-form-item v-if="item.type && item.type !== '无成绩'" label="成绩分数：" :prop="`foreignLanguageMark[${index}].total_score`" :rules="[{ required: true, message: '请输入分数', trigger: 'blur' }, { validator: checksResult, trigger: 'blur' }]">
                                <el-input v-model.trim="userForm.foreignLanguageMark[index].total_score" v-number="1" placeholder="请输入分数" style="width:300px" />
                            </el-form-item>
                            <!-- 雅思 - 听说读写 -->
                            <template v-if="item.type === '雅思'">
                                <el-form-item label="听：" :prop="`foreignLanguageMark[${index}].listen_score`" :rules="[{ required: true, message: '请输入分数', trigger: 'blur' }, { validator: checksDetail, trigger: 'blur' }]">
                                    <el-input v-model.trim="userForm.foreignLanguageMark[index].listen_score" v-number="1" placeholder="请输入分数" style="width:300px" />
                                </el-form-item>
                                <el-form-item label="说：" :prop="`foreignLanguageMark[${index}].speak_score`" :rules="[{ required: true, message: '请输入分数', trigger: 'blur' }, { validator: checksDetail, trigger: 'blur' }]">
                                    <el-input v-model.trim="userForm.foreignLanguageMark[index].speak_score" v-number="1" placeholder="请输入分数" style="width:300px" />
                                </el-form-item>
                                <el-form-item label="读：" :prop="`foreignLanguageMark[${index}].read_score`" :rules="[{ required: true, message: '请输入分数', trigger: 'blur' }, { validator: checksDetail, trigger: 'blur' }]">
                                    <el-input v-model.trim="userForm.foreignLanguageMark[index].read_score" v-number="1" placeholder="请输入分数" style="width:300px" />
                                </el-form-item>
                                <el-form-item label="写：" :prop="`foreignLanguageMark[${index}].write_score`" :rules="[{ required: true, message: '请输入分数', trigger: 'blur' }, { validator: checksDetail, trigger: 'blur' }]">
                                    <el-input v-model.trim="userForm.foreignLanguageMark[index].write_score" v-number="1" placeholder="请输入分数" style="width:300px" />
                                </el-form-item>
                            </template>
                            <!-- 加号/减号 -->
                            <div class="toAdd">
                                <i v-if="index === 0 && userForm.foreignLanguageMark.length < 4 && item.type && item.type !== '无成绩'" class="el-icon-circle-plus-outline" @click="addEnglishType" />
                                <i v-if="index !== 0" class="el-icon-remove-outline" @click="delEnglishType(index)" />
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="operations-group">
            <el-button v-if="(user.baseSchedule - 1) < 1" class="person-btn" :loading="isSave" @click="saveForm('save')">保存</el-button>
            <el-button class="person-btn btn-blue" @click="saveForm('userForm')">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import CitySelect from "@/components/CitySelect/CitySelect";
import { saveInformation, querySearchCountry, getPingYin, getZipCode } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
import { setTimeout } from "timers";
import { date } from '../../../../utils/utils';
export default {
    name: "User",
    components: {
        CitySelect,
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            entranceOptions: {
                disabledDate(time) {
                    let interval = Number(time.getFullYear() - new Date().getFullYear())
                    return interval < 0 || interval >= 5
                }
            },
            labelPosition: "left",
            opctionLoading: false,
            countryOption: [], // 国籍列表
            checked: false,
            disabled: false,
            option: "save",
            userForm: {
                id: "",
                orderId: Number(this.$route.params.id),
                username: "", // 主申请人姓名
                pinyin: "",
                usedName: "", // 曾用名
                isHandled: 0, // 暂未办理
                passNumber: "", // 港澳通行证
                identityCard: "", // 身份证或护照
                sex: "", // 性别:0为未知,1为男,2为女
                mobile: "", // 手机号
                email: "", // 邮箱
                married: "", // 婚姻状态 1=已婚,2=未婚,3=离婚,4=分居 5-丧偶
                birthday: null, // 生日
                liveCountry: "", // 现定居国家
                hkidentityCard: "",
                surname: "",
                address: {
                    // 现居住地址
                    area: [],
                    foreign: "",
                    details: "",
                },
                postcode: "", // 邮编
                birthPlace: {
                    // 出生城市
                    area: [],
                    foreign: "",
                },
                birthPlaceAboard: 0, // 出生城市  0-国内 1-国外
                addressAboard: 0, // 现居住地址 0-国内 1-国外
                // ieltsOrToeflScore: "", //雅思或托福成绩：
                foreignLanguageMark: [],
                enrollmentDate: {
                    date: "",
                    quarter: "",
                },
                season: "",
                englishSeniorityType: "", //他英语资格类型 1.CTE-6 2.其他
                seniorityName: "", //其他英语资格类型补充说明，CET-6时此字段为分数，其他时该字段为备注信息
                nationalityType: "", //1-国内 2-国外 3-通过搜索进来
                nationality: "" //	国外国籍信息
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入主申姓名",
                        trigger: "change",
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                birthday: [
                    {
                        required: true,
                        message: "请选择出生日期",
                        trigger: "change",
                    },
                ],
                birthPlace: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "change",
                    },
                ],
                email: [
                    {
                        required: true,
                        validator: this.$rules.validate.email,
                        trigger: "blur",
                    },
                ],
                liveCountry: [
                    {
                        required: true,
                        message: "请输入现定居国家",
                        trigger: "change",
                    },
                ],
                birthPlaceAboard: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                addressAboard: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                married: [
                    {
                        required: true,
                        message: "请选择婚姻状况",
                        trigger: "change",
                    },
                ],
                "address.details": [
                    {
                        required: true,
                        message: "请输入详细地址",
                        trigger: "change",
                    },
                ],
                // ieltsOrToeflScore: [
                //     {
                //         required: true,
                //         message: "请输入雅思或托福成绩",
                //         trigger: "blur",
                //     },
                // ],
                englishSeniorityType: [
                    {
                        required: true,
                        validator: this.validEnglishSeniorityType,
                        trigger: "change",
                    },
                ],
                seniorityName: [
                    {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                    },
                ],
                nationalityType: [
                    {
                        required: true,
                        validator: this.validNationalityType,
                        trigger: "change",
                    },
                ],
                nationality: [
                    {
                        required: true,
                        message: "请填写国家名称",
                        trigger: "blur",
                    },
                ],
                'enrollmentDate.date': [{
                    required: true,
                    message: "请选择年份",
                    trigger: "change"
                }],
                'enrollmentDate.quarter': [{
                    required: true,
                    message: "请选择季度",
                    trigger: "change"
                }],
            },
            isSave: false, //防抖loading
            englishType: [
                { label: "雅思", value: "雅思", disabled: false },
                { label: "托福", value: "托福", disabled: false },
                { label: "GRE", value: "GRE", disabled: false },
                { label: "GMAT", value: "GMAT", disabled: false },
                { label: "无成绩", value: "无成绩", disabled: false }
            ],
            englishBasic: {
                type: "",
                total_score: "",
                listen_score: "",
                speak_score: "",
                read_score: "",
                write_score: "",
            },
        };
    },
    computed: {
        ...mapState(["user"]),
        quarterList() {
        if (this.userForm.enrollmentDate && ['春季', '秋季'].includes(this.userForm.enrollmentDate.quarter)) {
          return [
            { label: "春季", value: "春季"},
            { label: "秋季", value: "秋季"},
          ]
        }
          return [
            { label: "1月", value: "1月"},
            { label: "9月", value: "9月"},
          ]
        }
    },
    watch: {
        checked(val) {
            this.userForm.isHandled = !val ? 0 : 1;
            this.disabled = val ? true : false;
        },
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 数据回显
        dataEcho() {
            this.getInfoData(this.$route.params.id).then((res) => {
                if (res.code == 200) {
                    if(!res.data.information) {
                        this.userForm.foreignLanguageMark.push(JSON.parse(JSON.stringify(this.englishBasic)))
                    }
                    if(res.data.information && (!res.data.information.foreignLanguageMark || res.data.information.foreignLanguageMark.length === 0)) {
                        res.data.information.foreignLanguageMark = []
                        res.data.information.foreignLanguageMark.push(JSON.parse(JSON.stringify(this.englishBasic)))
                    }
                    if(res.data.information && !res.data.information.enrollmentDate) {
                        res.data.information.enrollmentDate = { date: "", quarter: "" }
                    }
                    this.userForm = res.data.information
                        ? res.data.information
                        : this.userForm;
                    this.checked = this.userForm.isHandled == 1 ? true : false;
                    this.disabled = this.userForm.isHandled == 1 ? true : false;
                    this.userForm.sex =
                        this.userForm.sex === 0 ? "" : this.userForm.sex;
                    this.checkEnglishSelected()
                    let pams = res.data.information
                    if (pams) {
                        if(pams.username_pinyin) {
                            this.$set(this.userForm, 'pinyin', pams.username_pinyin.family_name + ' ' + pams.username_pinyin.given_name);
                        } else {
                            this.$set(this.userForm, 'pinyin', "");
                        }
                        this.$set(this.userForm, 'postcode', pams.postcode)
                        if (pams.has_hk_id && pams.has_hk_id.has === 'Y') {
                            this.$set(this.userForm, 'hkidentityCard', pams.has_hk_id.info)
                        }
                    }

                }
            });
        },
        // 增加成绩类型
        addEnglishType() {
            this.userForm.foreignLanguageMark.push(JSON.parse(JSON.stringify(this.englishBasic)))
            this.englishType.find(item => item.value === '无成绩').disabled = true
        },
        // 删除成绩类型
        delEnglishType(index) {
            this.userForm.foreignLanguageMark.splice(index, 1)
            this.checkEnglishSelected()
        },
        // 修改成绩类型
        changeEnType(val, index) {
            this.checkEnglishSelected()
            this.userForm.foreignLanguageMark[index].total_score = ""
            this.$refs['userForm'].clearValidate(`foreignLanguageMark[${index}].total_score`)
        },
        // 控制disabled
        checkEnglishSelected() {
            let curSelected = this.userForm.foreignLanguageMark.filter(item => item.type).map(item => item.type)
            this.englishType.forEach(item => {
                item.disabled = curSelected.includes(item.value)
            })
            if(this.userForm.foreignLanguageMark.length < 2) this.englishType.find(item => item.value === '无成绩').disabled = false
            if(this.userForm.foreignLanguageMark.length >1) this.englishType.find(item => item.value === '无成绩').disabled = true
        },
        addApplyer(v) {
            this.userForm.nationalityType = 3;
            if (v == 2) {
                if (this.userForm.birthPlaceAboard == 0) {
                    if (
                        this.userForm.birthPlace.area.length == 0 ||
                        !this.userForm.birthPlace.area.filter(v => v).length
                    ) {
                        this.$message.error("请选择出生城市");
                        return false;
                    }
                }
                if (this.userForm.birthPlaceAboard == 1) {
                    if (
                        this.userForm.birthPlace.foreign == "" ||
                        !this.userForm.birthPlace.foreign
                    ) {
                        this.$message.error("请输入出生城市");
                        return false;
                    }
                }
                if (this.userForm.addressAboard == 0) {
                    if (
                        this.userForm.address.area.length == 0 ||
                        !this.userForm.address.area.filter(v => v).length
                    ) {
                        this.$message.error("请选择现居住地址");
                        return false;
                    }
                }
                if (
                    !/^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(
                        this.userForm.email
                    ) &&
                    this.userForm.email !== "" &&
                    this.userForm.email !== undefined
                ) {
                    this.$message.error("请输入正确的邮箱");
                    return false;
                }
                if (this.userForm.addressAboard == 1) {
                    if (
                        this.userForm.address.foreign == "" ||
                        !this.userForm.address.foreign
                    ) {
                        this.$message.error("请输入现居住地址");
                        return false;
                    }
                }
                if (
                    this.userForm.passNumber == undefined &&
                    this.userForm.identityCard == undefined
                ) {
                    this.$message.error("请输入港澳通行证或身份证号码");
                    return false;
                }
                if (
                    (this.userForm.passNumber == "" &&
                        !this.userForm.identityCard) ||
                    (!this.userForm.passNumber &&
                        this.userForm.identityCard == "")
                ) {
                    this.$message.error("请输入港澳通行证或身份证号码");
                    return false;
                }
                // 校验身份证
                if (
                    this.userForm.identityCard !== "" &&
                    this.userForm.identityCard !== undefined
                ) {
                    if (
                        !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
                            this.userForm.identityCard
                        )
                    ) {
                        this.$message.error("请输入正确的身份证号码");
                        return false;
                    }
                }
                let family_name = ''
                let given_name = ''
                const index = this.userForm.pinyin.indexOf(" ")
                console.log(index)
                if (index === -1) {
                    family_name = ''
                    given_name = this.userForm.pinyin
                } else {
                    family_name = this.userForm.pinyin.slice(0, index)
                    given_name = this.userForm.pinyin.slice(index + 1)
                }
                this.userForm.username_pinyin = {
                    family_name: family_name,
                    given_name: given_name
                }
                // 校验港澳通行证 2021.5.26 文龙说取消校验 最长20个字
                // if (
                //     this.userForm.passNumber !== "" &&
                //     this.userForm.passNumber !== undefined
                // ) {
                //     if (!/[a-z]/i.test(this.userForm.passNumber[1])) {
                //         //老版通行证
                //         if (!/^[CW]\d{8}$/.test(this.userForm.passNumber)) {
                //             this.$message.error("请输入正确的港澳通行证");
                //             return false;
                //         }
                //     } else {
                //         // 新版通行证
                //         if (
                //             this.userForm.passNumber.indexOf("I") === -1 &&
                //             this.userForm.passNumber.indexOf("O") === -1
                //         ) {
                //             if (
                //                 !/^C[A-Z]\d{7}$/.test(this.userForm.passNumber)
                //             ) {
                //                 this.$message.error("请输入正确的港澳通行证");
                //                 return false;
                //             }
                //         } else {
                //             this.$message.error("请输入正确的港澳通行证");
                //             return false;
                //         }
                //     }
                // }
            }
            this.option = v == 1 ? "save" : "submit";
            if (this.userForm.hkidentityCard) {
                this.userForm.has_hk_id = {
                    has: 'Y',
                    info: this.userForm.hkidentityCard
                }
            }
            return new Promise((resolve, reject) => {
                saveInformation({
                    information: this.userForm,
                    option: this.option,
                }).then((res) => {
                    if (res.code == 200) {
                        if (v == 2) {
                            this.$message.success("提交成功");
                            // 切换步骤保存 下一步的时候才设置
                            this.saveUserData(2);
                            setTimeout(() => {
                                this.$router.replace({
                                    name: "ltep2",
                                });
                            }, 500);
                        } else {
                            this.dataEcho();
                            this.$message.success("保存成功！");
                            this.isSave = false;
                        }
                        resolve(res);
                    } else {
                        this.$message.error(res.msg);
                        this.isSave = false;
                        reject(res);
                    }
                });
            });
        },
        //保存表单
        saveForm(formData) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            if (formData == "save") {
                this.isSave = true;
                return this.addApplyer(1);
            }
            this.$refs[formData].validate((valid) => {
                if (valid) {
                    this.addApplyer(2);
                } else {
                    return false;
                }
            });
        },
        // 更改现居住地址
        changeAddress(v) {
            if (v === 0) {
                this.userForm.address.area = ["", ""];
            } else {
                this.userForm.address.foreign = "";
            }
        },
        changeSeniorityType(v) {
            if(v) this.userForm.seniorityName = ""
        },
        // 更改出生城市
        changeBirthPalce(e, item) {
            if (e === 0) {
                item.birthPlace.foreign = "";
            } else {
                item.birthPlace.area = ["", ""];
            }
        },
        // 验证国籍
        validNationalityType(rule, value, callback) {
            if(!value || value === 0) {
                return callback(new Error('请选择国籍'))
            }
            return callback()
        },
        // 验证其他英语资validEnglishSeniorityType格
        validEnglishSeniorityType(rule, value, callback) {
            if(!value || value === 0) {
                return callback(new Error('请选择其他英语资格'))
            }
            return callback()
        },
        // 验证英语分数是否在对应区间
        checksResult(rule, value, callback) {
            let curIndex = rule.field.split('[')[1].slice(0 ,1)
            let curType = this.userForm.foreignLanguageMark[curIndex].type
            let NumberValue = Number(value)
            switch (curType) {
                case "雅思": {
                    if(isNaN(NumberValue)) return callback(new Error('请输入数字'))
                    if(NumberValue > 9 || NumberValue < 0) return callback(new Error('请输入0~9之间的数字'))
                    return callback()
                }
                case "托福": {
                    if(isNaN(NumberValue)) return callback(new Error('请输入数字'))
                    if(NumberValue > 120 || NumberValue < 0) return callback(new Error('请输入0~120之间的数字'))
                    return callback()
                }
                case "GRE": {
                    if(isNaN(NumberValue)) return callback(new Error('请输入数字'))
                    if(NumberValue > 340 || NumberValue < 0) return callback(new Error('请输入0~340之间的数字'))
                    return callback()
                }
                case "GMAT": {
                    if(isNaN(NumberValue)) return callback(new Error('请输入数字'))
                    if(NumberValue > 800 || NumberValue < 200) return callback(new Error('请输入200~800之间的数字'))
                    return callback()
                }
                default:
                    return callback()
            }
        },
        // 听说读写的分数校验
        checksDetail(rule, value, callback) {
            let NumberValue = Number(value)
            if(isNaN(NumberValue)) return callback(new Error('请输入数字'))
            if(NumberValue > 9 || NumberValue < 0) return callback(new Error('请输入0~9之间的数字'))
            return callback()
        },
        // 获取国籍
        queryCountryAsync(keyword) {
            this.opctionLoading = true;
            querySearchCountry({
                keyword,
            }).then((res) => {
                this.opctionLoading = false;
                this.countryOption = res.data;
            });
        },
        //  主申姓名失去焦点获取拼音
        usernameBlur() {
            if(!/^[\u4E00-\u9FFF\u3400-\u4DBF]+$/.test(this.userForm.username)) {
                this.userForm.pinyin = ''
                return
            }
            getPingYin({
                chinese: this.userForm.username,
            }).then((res) => {
                let given_name = res.data.given_name ? ' ' + res.data.given_name : ''
                this.userForm.pinyin = res.data.family_name + given_name
            });
        },
        //  获取邮编
        getZipCode() {
            getZipCode({
                address: this.userForm.address.area.join(''),
            }).then((res) => {
                this.userForm.postcode = res.data.zip_code
                this.$forceUpdate()
            });
        },
        // 姓名拼音只能输入 字母和空格
        pinyinInput() {
            this.userForm.pinyin = this.userForm.pinyin.replace(/[^a-zA-Z ]/g, '').toLocaleUpperCase();
        },
        // 邮编只能输入纯数字
        postcodeInput() {
            this.userForm.postcode = this.userForm.postcode.replace(/\D/g, "");
        }
    },
};
</script>

<style lang="less" scoped>
.card-container {
    width: 1000px;
    margin: 0 auto;
    .card-heard {
        height: 35px;
        line-height: 35px;
        background: #409eff;
        padding-left: 16px;
        border-radius: 4px 4px 0px 0px;
        color: #fff;
        margin-bottom: 16px;
    }
}

.operations-group {
    width: 960px;
    margin: 25px auto 50px;
    display: flex;
    justify-content: flex-end;
}

.demo-ruleForm {
    // margin-left: 8px;
    // height: 500px;
    padding: 0 20px;
}

.one-input {
    width: 400px;
    display: inline-block;
}

.doubles {
    margin-left: 60px;
}

.person-btn {
    line-height: 22px;
    font-size: 14px;
    color: #606266;
    padding: 5px 16px;
    border-radius: 2px;
    margin-left: 16px;
    border: 1px solid #dcdfe6;
}

.btn-blue {
    color: #ffffff;
    background: #409eff;
    border: none;
}

.input-width {
    width: 340px;
}

.input-width150 {
    width: 240px;
}
.el-radio {
    margin-right: 10px;
}
.last-one-input {
    position: relative;
    top: -100px;
    width: 484px;
    margin-left: 459px;
}

.en-score + .en-score {
    .divider {
        margin-bottom: 18px;
        height: 1px;
        background-color: #DCDFE6;
        width: 440px;
    }
}
.toAdd {
    padding-left: 140px;
    margin-bottom: 15px;
    >i {
        color: #999;
        font-size: 14px;
        cursor: pointer;
    }
}

.entrance-date {
    display: flex;
    >.el-form-item:nth-of-type(2) {
        margin-left: 15px;
    }
}
</style>
